import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { OrderIssuesService } from 'src/app/presentation/shared/services/orderIssues.service';
// Ignoring the linting check because this comes from a Kotlin Library
import { NgFor } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
// @ts-ignore
import { getCountryFromIsoCode3 } from '@taager-experience-shared/country-resolver';
import { OrderModel } from 'src/app/core/domain/order-model';
import { VariantModel } from 'src/app/core/domain/variant-group.model';
import { getSizedImage, ImageSize } from '../../shared/utilities/get-sized-image.utility';

@Component({
  selector: 'app-order-refunds',
  templateUrl: './order-refunds.component.html',
  styleUrls: ['./order-refunds.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
  ],
})
export class OrderRefundsComponent implements OnInit {
  public products: any = [];

  public product = {
    productObjectId: String,
    productId: String,
    productQty: Number,
    productProfit: Number,
  };

  public refundOrderForm: UntypedFormGroup;

  public clicked = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { order: OrderModel },
    private toastr: ToastrService,
    private orderIssuesService: OrderIssuesService,
    private dialogRef: MatDialogRef<OrderRefundsComponent>,
    private _translateService: TranslateService,
  ) {
    this.refundOrderForm = new UntypedFormGroup({
      product: new UntypedFormControl(),
      productQty: new UntypedFormControl(),
      issueReason: new UntypedFormControl(),
      phoneNum: new UntypedFormControl(null, [Validators.required]),
      notes: new UntypedFormControl(),
      issueImage: new UntypedFormControl(),
      issueVideo: new UntypedFormControl(),
    });
    dialogRef.disableClose = false;
  }

  addQuantity(): void {
    if (this.refundOrderForm.value.productQty < this.product.productQty) {
      this.refundOrderForm.get('productQty')!.setValue(this.refundOrderForm.value.productQty + 1);
    }
  }

  removeQuantity(): void {
    if (this.refundOrderForm.value.productQty > 1) {
      this.refundOrderForm.get('productQty')!.setValue(this.refundOrderForm.value.productQty - 1);
    }
  }

  ngOnInit(): void {
    this._doSetPhoneNumberValidators();
    this.products = this.data.order.orderLines.map(
      (orderLine) =>
        ({
          productName: `${orderLine.productName}${
            orderLine.productColor ? `, ${orderLine.productColor}` : ''
          }${orderLine.productSize ? `, ${orderLine.productSize}` : ''}`,
          productQuantity: orderLine.quantity,
          productProfit: orderLine.totalMerchantProfit,
          prodID: orderLine.productId,
          productPicture: getSizedImage(orderLine.productPicture!, ImageSize.small),
        } as VariantModel),
    );
  }

  private _doSetPhoneNumberValidators(): void {
    const { phoneRegex } = getCountryFromIsoCode3(this.data.order.country);
    this.refundOrderForm
      .get('phoneNum')!
      .addValidators([this.regexValidator(new RegExp(phoneRegex), { invalidPhoneNumber: true })]);
  }

  public regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  public OpenFile(video: any): void {
    if (video) {
      document.getElementById('formControlVideo')!.click();
    } else {
      document.getElementById('formControlImage')!.click();
    }
  }

  public onSendFile(event: any, video: any): void {
    this.clicked = true;
    this.toastr.info('Uploading file');
    const {
      target: { name, files },
    } = event;
    const c = 0;
    const formData = new FormData();
    formData.append('image', files[0]);
    const filesize = (files[0].size / 1024 / 1024).toFixed(4); // MB
    if (Number(filesize) < 15) {
      this.orderIssuesService.addIssueAttachment(formData).subscribe({
        next: (resp: any) => {
          this.clicked = false;
          this.toastr.success('Uploaded file');
          if (video) {
            this.refundOrderForm.get('issueVideo')!.setValue(resp.msg);
          } else {
            this.refundOrderForm.get('issueImage')!.setValue(resp.msg);
          }
        },
        error: (err) => {
          this.toastr.error(err.error.msg);
        },
      });
    } else {
      this.toastr.error(this._translateService.instant('ORDERS_PAGE.FILE_SIZE_ERROR'));
    }
  }

  onProductChange(event: any): void {
    this.product = {
      productObjectId: event.value._id,
      productQty: event.value.productQuantity,
      productProfit: event.value.productProfit,
      productId: event.value.prodID,
    };
    this.refundOrderForm.get('productQty')!.setValue(event.value.productQuantity);
  }

  private validatePhoneNum(phoneNum: string): boolean {
    if (!phoneNum || phoneNum === '') {
      this.toastr.error(this._translateService.instant('ORDERS_PAGE.MOBILE_FIELD_REQUIRED'));
      return false;
    }
    if (phoneNum.length > 11) {
      this.toastr.error(this._translateService.instant('ORDERS_PAGE.MOBILE_NUMBER_GREATER'));
      return false;
    }
    if (phoneNum.length < 11) {
      this.toastr.error(this._translateService.instant('ORDERS_PAGE.MOBILE_NUMBER_LESS'));
      return false;
    }
    if (!phoneNum.startsWith('01')) {
      this.toastr.error(this._translateService.instant('ORDERS_PAGE.MOBILE_NUMBER_START_01'));
      return false;
    }
    if (/\s/.test(phoneNum)) {
      this.toastr.error(this._translateService.instant('ORDERS_PAGE.MOBILE_NUMBER_SPACES'));
      return false;
    }
    if (!/^(01)[0-9]{9}$/.test(phoneNum)) {
      this.toastr.error(this._translateService.instant('ORDERS_PAGE.MOBILE_NUMBER_INTEGER'));
      return false;
    }
    return true;
  }

  submit(): number | undefined {
    if (!this.refundOrderForm.value.product) {
      this.toastr.error(
        this._translateService.instant('ORDERS_PAGE.REFUND_PRODUCT.CHOOSE_PRODUCT'),
      );
      return 0;
    }
    if (this.product.productQty < this.refundOrderForm.value.productQty) {
      this.toastr.error(
        this._translateService.instant('ORDERS_PAGE.REFUND_PRODUCT.MORE_THAN_ORDER_QUANTITY'),
      );
      return 0;
    }
    if (!this.refundOrderForm.value.issueReason) {
      this.toastr.error(
        this._translateService.instant('ORDERS_PAGE.REFUND_PRODUCT.REASON_FOR_RETURN'),
      );
      return 0;
    }
    if (this.clicked) {
      this.toastr.info('Uploading file');
      return 0;
    }
    if (this.refundOrderForm.get('phoneNum')!.invalid) {
      return;
    }
    this.clicked = true;
    this.product.productQty = this.refundOrderForm.value.productQty;
    const reqObj = {
      issueType: 1,
      order: {
        orderObjectId: this.data.order._id,
        OrderId: this.data.order.orderID,
      },
      product: this.product,
      issueReason: this.refundOrderForm.value.issueReason,
      notes: this.refundOrderForm.value.notes,
      phoneNum: this.refundOrderForm.value.phoneNum,
      issueImage: this.refundOrderForm.value.issueImage,
      issueVideo: this.refundOrderForm.value.issueVideo
        ? this.refundOrderForm.value.issueVideo
        : '',
    };

    this.orderIssuesService.addOrderRefund(reqObj).subscribe({
      next: (res: any) => {
        this.dialogRef.close({ data: 'confirmed' });
        this.toastr.success(
          this._translateService.instant('ORDERS_PAGE.REFUND_PRODUCT.REQUEST_SUCCESS'),
        );
      },
      error: (err) => {
        this.clicked = false;
        this.toastr.error(
          this._translateService.instant('ORDERS_PAGE.REFUND_PRODUCT.WALLET_BALANCE_INSUFFICIENT'),
        );
      },
    });
  }
}
