import { NgFor } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { OrderModel } from 'src/app/core/domain/order-model';
import { VariantModel } from 'src/app/core/domain/variant-group.model';
import { OrderIssuesService } from 'src/app/presentation/shared/services/orderIssues.service';
import { getSizedImage, ImageSize } from '../../shared/utilities/get-sized-image.utility';

@Component({
  selector: 'app-order-completion',
  templateUrl: './order-completion.component.html',
  styleUrls: ['./order-completion.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
  ],
})
export class OrderCompletionComponent implements OnInit {
  public products: VariantModel[] = [];

  public selectedProduct: {
    productObjectId: string;
    productQty: number;
    productProfit: number;
    productId: number;
  };

  public completeOrderForm: FormGroup<{
    product: FormControl<string>;
    productQty: FormControl<number>;
    issueReason: FormControl<string>;
    phoneNum: FormControl<string>;
    notes: FormControl<string>;
  }>;

  public clicked = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { order: OrderModel },
    private toastr: ToastrService,
    private orderIssuesService: OrderIssuesService,
    private dialogRef: MatDialogRef<OrderCompletionComponent>,
    private _translateService: TranslateService,
  ) {
    this.completeOrderForm = new FormGroup({
      product: new FormControl(),
      productQty: new FormControl(),
      issueReason: new FormControl(),
      phoneNum: new FormControl(),
      notes: new FormControl(),
    });
    dialogRef.disableClose = false;
  }

  ngOnInit(): void {
    this.products = this.data.order.orderLines.map(
      (orderLine) =>
        ({
          productName: `${orderLine.productName}${
            orderLine.productColor ? `, ${orderLine.productColor}` : ''
          }${orderLine.productSize ? `, ${orderLine.productSize}` : ''}`,
          productQuantity: orderLine.quantity,
          productProfit: orderLine.totalMerchantProfit,
          prodID: orderLine.productId,
          productPicture: getSizedImage(orderLine.productPicture!, ImageSize.small),
        } as VariantModel),
    );
  }

  addQuantity() {
    if (this.completeOrderForm.value.productQty! < this.selectedProduct.productQty) {
      this.completeOrderForm
        .get('productQty')!
        .setValue(this.completeOrderForm.value.productQty! + 1);
    }
  }

  removeQuantity(): void {
    if (this.completeOrderForm.value.productQty! > 1) {
      this.completeOrderForm
        .get('productQty')!
        .setValue(this.completeOrderForm.value.productQty! - 1);
    }
  }

  onProductChange(event: any): void {
    this.selectedProduct = {
      productObjectId: event.value._id,
      productQty: event.value.productQuantity,
      productProfit: event.value.productProfit,
      productId: event.value.prodID,
    };
    this.completeOrderForm.get('productQty')!.setValue(event.value.productQuantity);
  }

  submit(): any {
    if (!this.completeOrderForm.value.product) {
      this.toastr.error(
        this._translateService.instant(
          'ORDERS_PAGE.ORDER_COMPLETION_DIALOG.PRODUCT_SELECTION_LABEL',
        ),
      );
      return 0;
    }
    if (this.selectedProduct.productQty < this.completeOrderForm.value.productQty!) {
      this.toastr.error(
        this._translateService.instant('ORDERS_PAGE.REFUND_PRODUCT.MORE_THAN_ORDER_QUANTITY'),
      );
      return 0;
    }
    if (!this.completeOrderForm.value.notes) {
      this.toastr.error(
        this._translateService.instant('ORDERS_PAGE.ORDER_COMPLETION.ADD_DETAILS_FOR_ISSUE'),
      );
      return 0;
    }
    this.clicked = true;
    this.selectedProduct.productQty = this.completeOrderForm.value.productQty!;
    const reqObj = {
      issueType: 3,
      order: {
        orderObjectId: this.data.order._id,
        OrderId: this.data.order.orderID,
      },
      product: this.selectedProduct,
      notes: this.completeOrderForm.value.notes,
    };
    this.orderIssuesService.addOrderCompletion(reqObj).subscribe({
      next: (res: any) => {
        this.dialogRef.close({ data: 'confirmed' });
        this.toastr.success(
          this._translateService.instant('ORDERS_PAGE.REFUND_PRODUCT.REQUEST_SUCCESS'),
        );
      },
      error: (err) => {
        this.clicked = false;
      },
    });
  }
}
