<div mat-dialog-content>
  <h4 mat-dialog-title>
    {{ 'ORDERS_PAGE.ORDER_COMPLETION_DIALOG.COMPLETE_TITLE' | translate }}
  </h4>
  <form [formGroup]="completeOrderForm">
    <mat-form-field>
      <mat-label>{{
        'ORDERS_PAGE.ORDER_COMPLETION_DIALOG.PRODUCT_SELECTION_LABEL' | translate
      }}</mat-label>
      <mat-select formControlName="product" (selectionChange)="onProductChange($event)" required>
        <mat-option *ngFor="let item of products" [value]="item">
          <img loading="lazy" width="30px" height="30px" [src]="item.productPicture" />
          {{ item.productName }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="none">
      <label class="matLabel">{{
        'ORDERS_PAGE.ORDER_ACTIONS_DIALOGS.PRODUCT_QUANTITY_LABEL' | translate
      }}</label>
      <div class="inline-div">
        <label (click)="addQuantity()">+</label>
        <input type="number" matInput formControlName="productQty" class="qty-input" />
        <label (click)="removeQuantity()">-</label>
      </div>
    </mat-form-field>

    <mat-form-field appearance="none">
      <label class="matLabel">{{
        'ORDERS_PAGE.ORDER_ACTIONS_DIALOGS.EXPLAINATION_NOTES_LABEL' | translate
      }}</label>
      <textarea formControlName="notes" matInput></textarea>
    </mat-form-field>
  </form>
  <div mat-dialog-actions>
    <button mat-raised-button [disabled]="clicked" (click)="submit()" class="btn-submit">
      {{ 'ORDERS_PAGE.ORDER_ACTIONS_DIALOGS.SUBMIT_REQUEST_BTN_LABEL' | translate }}
    </button>
  </div>
</div>
