<div *ngIf="order" class="main web-only" [ngClass]="{ 'border-around': shouldEnableOrdersRevamp }">
  <div class="title {{ hasPrepaidFeature ? 'has-prepaid' : '' }}">
    <ng-template [ngTemplateOutlet]="prepaidTemplate"></ng-template>
    <div class="from-lead" *ngIf="order.orderSource?.fromLead">
      <div class="info-status">
        <img [src]="assetsPath + 'tiktok.svg'" />
        <p>{{ 'ORDERS_PAGE.LEADS.FROM_LEAD_LABEL' | translate }}</p>
      </div>
    </div>

    <div class="title-cell">
      <div class="code-title">
        {{
          !isDraftOrder
            ? ('ORDERS_PAGE.ORDER_CODE' | translate)
            : ('ORDERS_PAGE.DRAFT_ORDER_CODE' | translate)
        }}
      </div>
      <div class="code-value">{{ order.orderID || order.orderId }}</div>
    </div>
    <div class="title-cell">
      <div class="date-title">{{ 'ORDERS_PAGE.ORDER_STATUS' | translate }}</div>
      <button
        class="btn status-badge"
        [ngClass]="{
          'success-light': statusStage === 'received',
          'success-dark': statusStage === 'delivered',
          'error-light': statusStage === 'cancelled',
          'warn-status': statusStage === 'pending',
          'success-standard': statusStage === 'confirmation',
          'standard-status': statusStage === 'suspended'
        }"
      >
        <ng-container
          *ngIf="shouldEnableOrdersRevamp && order.detailedStatus; else showStandardStatus"
        >
          {{
            isEnglishLanguage
              ? order.detailedStatus?.customerDescription?.en
              : order.detailedStatus?.customerDescription?.ar
          }}
        </ng-container>
        <ng-template #showStandardStatus>
          {{ returnOrderStatus(order.status) | translate }}
        </ng-template>
      </button>
    </div>
    <ng-container *ngIf="!draft">
      <div class="title-cell">
        <div class="date-title">{{ 'ORDERS_PAGE.ORDER_CREATION_DATE' | translate }}</div>
        <div class="date-value">{{ convertDate(order.createdAt) }}</div>
      </div>
      <div class="title-cell">
        <div *ngIf="order.confirmationDate" class="date-title">
          {{ 'ORDERS_PAGE.ORDER_CONFRIMATION_DATE' | translate }}
        </div>
        <div *ngIf="order.confirmationDate" class="date-value">
          {{ convertDate(order.confirmationDate) }}
        </div>
      </div>
      <div class="title-cell">
        <div
          *ngIf="order.orderReceivedBy === 'telesales' || order.orderSource?.fromProductCheckout"
          class="code-title"
        >
          {{ 'ORDERS_PAGE.ORDER_SOURCE' | translate }}
        </div>
        <div
          *ngIf="order.orderReceivedBy === 'telesales'"
          class="tele-sales-badge__pill body1--medium"
        >
          {{ 'ORDERS_PAGE.TELE_SALES_ORDER' | translate }}
        </div>
        <div
          *ngIf="order.orderSource?.fromProductCheckout"
          class="tele-sales-badge__info body1--medium"
        >
          {{ 'ORDERS_PAGE.PRODUCT_CHECKOUT_ORDER' | translate }}
        </div>
      </div>
    </ng-container>
  </div>
  <div class="details-container">
    <div class="details">
      <div class="inline-div">
        <div class="unit">
          <div class="lab-title">{{ 'ORDERS_PAGE.CUSTOMER_NAME' | translate }}</div>
          <div class="lab-value">{{ order.receiverName }}</div>
        </div>
        <div class="unit">
          <div class="lab-title">{{ 'ORDERS_PAGE.CUSTOMER_MOBILE_NUMBER' | translate }}</div>
          <div class="lab-value">{{ order.phoneNumber }}</div>
        </div>
        <div class="unit">
          <div class="lab-title">{{ 'ORDERS_PAGE.PROVINCE' | translate }}</div>
          <div class="lab-value">{{ order.province }}</div>
        </div>
        <div class="unit">
          <div class="lab-title">{{ 'ORDERS_PAGE.TOTAL_PRICE' | translate }}</div>
          <div class="lab-value">
            {{ order.cashOnDelivery }}
            {{
              isEnglishLanguage
                ? orderCountry?.currency?.englishName
                : orderCountry?.currency?.arabicName || ''
            }}
          </div>
        </div>
        <div class="unit">
          <div class="lab-title">{{ 'ORDERS_PAGE.ORDER_PROFIT' | translate }}</div>
          <div class="lab-value">
            {{ order.orderProfit }}
            {{
              isEnglishLanguage
                ? orderCountry?.currency?.englishName
                : orderCountry?.currency?.arabicName || ''
            }}
          </div>
        </div>
        <div class="unit" *ngIf="order.vatProfit >= 0">
          <div class="lab-title">{{ 'ORDERS_PAGE.TAX' | translate }}</div>
          <div class="lab-value">
            {{ order.vatProfit }}{{ order.vatProfit > 0 ? '-' : '' }}
            {{ orderCountry?.currency?.arabicName || '' }}
          </div>
        </div>
        <div class="unit" *ngIf="order.vatProfit >= 0">
          <div class="lab-title">{{ 'ORDERS_PAGE.NET_PROFIT' | translate }}</div>
          <div class="lab-value">
            {{ order.orderProfit - order.vatProfit }}
            {{
              isEnglishLanguage
                ? orderCountry?.currency?.englishName
                : orderCountry?.currency?.arabicName || ''
            }}
          </div>
        </div>
      </div>
      <div class="inline-div">
        <div class="unit">
          <div class="lab-title">{{ 'ORDERS_PAGE.CUSTOMER_ADDRESS' | translate }}</div>
          <div class="lab-value">{{ order.streetName }}</div>
        </div>
        <div class="unit">
          <div class="lab-title">{{ 'ORDERS_PAGE.SHIPPED_TO' | translate }}</div>
          <div class="lab-value">{{ orderCountry.arabicName }}</div>
        </div>
        <div class="unit">
          <div class="lab-title">{{ 'ORDERS_PAGE.ORDER_NOTES' | translate }}</div>
          <div class="lab-value">
            {{ order.notes || ('ORDERS_PAGE.NO_NOTES_LABEL' | translate) }}
            {{ additionalNote ? ' - ' + additionalNote : '' }}
          </div>
        </div>
        <div class="unit" *ngIf="order.shippingInfo?.trackingLink">
          <div class="lab-title">
            {{ 'ORDERS_PAGE.ORDER_TRACKING_TITLE' | translate }}
          </div>
          <div class="lab-value track-link">
            <span (click)="openTrackingLink(order.shippingInfo?.trackingLink)">{{
              'ORDERS_PAGE.ORDER_TRACKING_DIALOG.TRACK_SHIPMENT' | translate
            }}</span>
            <i
              (click)="copyTrackingLink(order.shippingInfo?.trackingLink)"
              class="icon icon-copy"
            ></i>
          </div>
        </div>
        <div *ngIf="order.status === 'order_received' || order.status === 'suspended'" class="unit">
          <div class="lab-title">{{ 'ORDERS_PAGE.ORDER_CONFIRMATION_NOTES' | translate }}</div>
          <div class="lab-value">{{ order.failedAttemptNote }}</div>
        </div>
        <div
          *ngIf="order.status === 'delivery_suspended' || order.status === 'cancel'"
          class="unit"
        >
          <div class="lab-title">{{ 'ORDERS_PAGE.SUSPENSION_REASON' | translate }}</div>
          <div class="lab-value">
            {{ matchDeliverySuspendedReason(order.deliverySuspendedReason) }}
          </div>
        </div>
      </div>
    </div>
    <div class="status action-btn">
      <button class="btn btn-viewOrder" (click)="onItemsDetails(order)">
        {{ 'ORDERS_PAGE.SHOW_ORDER_BTN_LABEL' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-viewOrder"
        *ngIf="!isDraftOrder"
        (click)="onTrackOrderActivity(order)"
      >
        {{ 'ORDERS_PAGE.TRACK_ORDER_BTN_LABEL' | translate }}
      </button>
      <button
        *ngIf="!draft && order.shippingInfo && shippmentTrackingExists"
        class="btn btn-message"
        (click)="reloadShipmentStatus()"
      >
        {{ 'ORDERS_PAGE.TRACK_SHIPMENT_BTN_LABEL' | translate }}
      </button>
      <button *ngIf="!draft" class="btn btn-message" (click)="openChatModel(order)">
        {{ 'ORDERS_PAGE.SEND_MESSAGE_BTN_LABEL' | translate }}
      </button>
      <button
        *ngIf="order.status === 'order_received' || order.status === 'suspended'"
        class="btn btn-cancel"
        (click)="changeOrderStatus(order)"
      >
        {{ 'ORDERS_PAGE.CANCEL_BTN_LABEL' | translate }}
      </button>
      <button
        *ngIf="checkChildOrders(order.status)"
        class="btn btn-message"
        (click)="onChildOrdersDetails()"
      >
        {{ 'ORDERS_PAGE.REPLACEMENTS_RETURNS_BTN_LABEL' | translate }}
      </button>
      <button *ngIf="!draft" class="btn btn-message" (click)="rateOrder()">
        {{ 'ORDERS_PAGE.REVIEW_ORDER_BTN_LABEL' | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="order.hasIssue" class="aftersale-container">
    <div class="order-issue">{{ 'ORDERS_PAGE.ORDER_ISSUE_MESSAGE' | translate }}</div>
  </div>
  <div
    *ngIf="order.hasIssue === false && !(order.resolved && statusStage === 'delivered')"
    class="aftersale-container"
  >
    <div class="order-issue" [ngClass]="{ rejected: order.resolved === false }">
      {{ orderIssueMessage }}
    </div>
  </div>
  <div *ngIf="statusStage === 'delivered' && !order.hasIssue" class="aftersale-container">
    <div class="inline-div">
      <div class="lab-value">{{ 'ORDERS_PAGE.ISSUE_WITH_YOUR_ORDER' | translate }}</div>
    </div>
    <div class="inline-div">
      <button class="btn btn-return" (click)="openOrderReplacements(order)">
        {{ 'ORDERS_PAGE.REPLACE_PRODUCT' | translate }}
      </button>
      <button
        *ngIf="showAdditon"
        class="btn btn-return btn-complete"
        (click)="openOrderCompletion(order)"
      >
        {{ 'ORDERS_PAGE.COMPLETE_ORDER' | translate }}
      </button>
      <button class="btn btn-return btn-replace" (click)="openOrderRefunds(order)">
        {{ 'ORDERS_PAGE.RETURN_ORDER' | translate }}
      </button>
      <button class="btn btn-policy" (click)="openRefundPolicy()">
        {{ 'ORDERS_PAGE.RETURN_REPLACEMENT_POLICY' | translate }}
      </button>
    </div>
  </div>
  <p *ngIf="order.errorMessage" class="bulk-order-error">
    {{ order.errorMessage | translate }}
  </p>
</div>

<div *ngIf="order" class="main mobile-only">
  <div class="title {{ hasPrepaidFeature ? 'has-prepaid' : '' }}">
    <ng-template [ngTemplateOutlet]="prepaidTemplate"></ng-template>
    <div class="code-title">{{ 'ORDERS_PAGE.ORDER_CODE' | translate }}</div>
    <div class="code-value">{{ order.orderID }}</div>
    <button
      class="btn status-badge"
      [ngClass]="{
        received: statusStage === 'received',
        delivered: statusStage === 'delivered',
        cancelled: statusStage === 'cancelled',
        pending: statusStage === 'pending'
      }"
    >
      {{ returnOrderStatus(order.status) | translate }}
    </button>
  </div>
  <div class="details-container">
    <div class="details-mobile">
      <div class="inline-div">
        <div class="unit-mobile">
          <div class="lab-title">{{ 'ORDERS_PAGE.CUSTOMER_NAME' | translate }}</div>
          <div class="lab-value">{{ order.receiverName }}</div>
        </div>
        <div class="unit-mobile-1">
          <div class="lab-title">{{ 'ORDERS_PAGE.PROVINCE' | translate }}</div>
          <div class="lab-value">{{ order.province }}</div>
        </div>
      </div>
      <div class="inline-div">
        <div class="unit-mobile-1">
          <div class="lab-title">{{ 'ORDERS_PAGE.TOTAL_PRICE' | translate }}</div>
          <div class="lab-value">
            {{ order.cashOnDelivery }} {{ orderCountry?.currency?.arabicName || '' }}
          </div>
        </div>
        <div class="unit-mobile-1">
          <div class="lab-title">{{ 'ORDERS_PAGE.ORDER_PROFIT' | translate }}</div>
          <div class="lab-value">
            {{ order.orderProfit }} {{ orderCountry?.currency?.arabicName || '' }}
          </div>
        </div>
        <div class="unit-mobile-1" *ngIf="order.vatProfit >= 0">
          <div class="lab-title">{{ 'ORDERS_PAGE.TAX' | translate }}</div>
          <div class="lab-value">
            {{ order.vatProfit }}{{ order.vatProfit > 0 ? '-' : '' }}
            {{ orderCountry?.currency?.arabicName || '' }}
          </div>
        </div>
        <div class="unit-mobile-1" *ngIf="order.vatProfit >= 0">
          <div class="lab-title">{{ 'ORDERS_PAGE.NET_PROFIT' | translate }}</div>
          <div class="lab-value">
            {{ order.orderProfit - order.vatProfit }} {{ orderCountry?.currency?.arabicName || '' }}
          </div>
        </div>
        <div class="unit-mobile-1">
          <div class="lab-title">{{ 'ORDERS_PAGE.CUSTOMER_MOBILE_NUMBER' | translate }}</div>
          <div class="lab-value">{{ order.phoneNumber }}</div>
        </div>
      </div>
      <div class="inline-div">
        <div class="unit-mobile-1">
          <div class="lab-title">{{ 'ORDERS_PAGE.CUSTOMER_ADDRESS' | translate }}</div>
          <div class="lab-value">{{ order.streetName }}</div>
        </div>
        <div class="unit-mobile-1">
          <div class="lab-title">{{ 'ORDERS_PAGE.SHIPPED_TO' | translate }}</div>
          <div class="lab-value">{{ orderCountry.arabicName }}</div>
        </div>
        <div class="unit-mobile-1">
          <div class="lab-title">{{ 'ORDERS_PAGE.ORDER_NOTES' | translate }}</div>
          <div class="lab-value">
            {{ order.notes || ('ORDERS_PAGE.NO_NOTES_LABEL' | translate) }}
            {{ additionalNote ? ' - ' + additionalNote : '' }}
          </div>
        </div>
      </div>
      <button class="btn btn-viewOrder" (click)="onItemsDetails(order)">
        {{ 'ORDERS_PAGE.SHOW_ORDER_BTN_LABEL' | translate }}
      </button>
      <button type="button" class="btn btn-message" (click)="onTrackOrderActivity(order)">
        {{ 'ORDERS_PAGE.TRACK_ORDER_BTN_LABEL' | translate }}
      </button>
      <button
        *ngIf="!draft && order.shippingInfo && shippmentTrackingExists"
        class="btn btn-message"
        (click)="reloadShipmentStatus()"
      >
        {{ 'ORDERS_PAGE.TRACK_SHIPMENT_BTN_LABEL' | translate }}
      </button>
      <button *ngIf="!draft" class="btn btn-message" (click)="openChatModel(order)">
        {{ 'ORDERS_PAGE.SEND_MESSAGE_BTN_LABEL' | translate }}
      </button>
      <button
        *ngIf="order.status === 'order_received'"
        class="btn btn-cancel"
        (click)="changeOrderStatus(order)"
      >
        {{ 'ORDERS_PAGE.CANCEL_BTN_LABEL' | translate }}
      </button>
      <button
        *ngIf="checkChildOrders(order.status)"
        class="btn btn-message"
        (click)="onChildOrdersDetails()"
      >
        {{ 'ORDERS_PAGE.REPLACEMENTS_RETURNS_BTN_LABEL' | translate }}
      </button>
      <button *ngIf="!draft" class="btn btn-message mb-3" (click)="rateOrder()">
        {{ 'ORDERS_PAGE.REVIEW_ORDER_BTN_LABEL' | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="order.hasIssue" class="aftersale-container">
    <div class="order-issue">{{ 'ORDERS_PAGE.ORDER_ISSUE_MESSAGE' | translate }}</div>
  </div>
  <div
    *ngIf="order.hasIssue === false && !(order.resolved && statusStage === 'delivered')"
    class="aftersale-container"
  >
    <div class="order-issue" [ngClass]="{ rejected: order.resolved === false }">
      {{ orderIssueMessage }}
    </div>
  </div>
  <div *ngIf="statusStage === 'delivered' && !order.hasIssue" class="aftersale-container">
    <div class="inline-div">
      <div class="lab-value">{{ 'ORDERS_PAGE.ISSUE_WITH_YOUR_ORDER' | translate }}</div>
    </div>
    <div class="inline-div">
      <button class="btn btn-return-mob" (click)="openOrderReplacements(order)">
        {{ 'ORDERS_PAGE.REPLACE_PRODUCT' | translate }}
      </button>
      <button
        *ngIf="showAdditon"
        class="btn btn-return-mob btn-complete"
        (click)="openOrderCompletion(order)"
      >
        {{ 'ORDERS_PAGE.COMPLETE_ORDER' | translate }}
      </button>
      <button class="btn btn-return-mob btn-replace" (click)="openOrderRefunds(order)">
        {{ 'ORDERS_PAGE.RETURN_ORDER' | translate }}
      </button>
    </div>
    <button class="btn btn-policy-mob" (click)="openRefundPolicy()">
      {{ 'ORDERS_PAGE.RETURN_REPLACEMENT_POLICY' | translate }}
    </button>
  </div>
</div>

<ng-template #prepaidTemplate>
  <div *ngIf="order.isPrePaid" class="prepaid-label">
    <div class="prepaid-label__indicator">
      <span class="prepaid-label__indicator__text">{{
        'ORDERS_PAGE.PREPAID_MARKER' | translate
      }}</span>
    </div>
  </div>
</ng-template>
