import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
// @ts-ignore
import * as variants from '@taager-experience-shared/variants';
import { ToastrService } from 'ngx-toastr';
import { FEATURE_FLAGS, ORDER_STATUSES } from 'src/app/presentation/shared/constants';
import { Currency } from 'src/app/presentation/shared/interfaces/countries';
import { ProductService } from 'src/app/presentation/shared/services/product.service';
import { ShippingService } from 'src/app/presentation/shared/services/shipping.service';
// Ignoring the linting check because this comes from a Kotlin Library
import { DOCUMENT, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { user } from '@features/user/data';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
// @ts-ignore
import { getRejectionReasonByReasonNumberUseCase } from '@taager-experience-shared/rejection-reasons';
// @ts-ignore
import { getSuspensionReasonByReasonNumberUseCase } from '@taager-experience-shared/suspension-reasons';
// @ts-ignore
import { getCountryFromIsoCode3 } from '@taager-experience-shared/country-resolver';
import { MerchantOrderPreferencesModel, OrderModel } from 'src/app/core/domain/order-model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { CurrencyShortNamePipe } from '../../shared/pipes/currency-short-name.pipe';
import { CurrencyTranslatePipe } from '../../shared/pipes/currency-translate.pipe';
import { DateWrapperUtility } from '../../shared/utilities/date-wrapper.utility';
import { getSizedImage, ImageSize } from '../../shared/utilities/get-sized-image.utility';
import { OrderPreferenceComponent } from '../order-preference/order-preference.component';

@Component({
  selector: 'app-order-item-dialog',
  templateUrl: './order-item-dialog.component.html',
  styleUrls: ['./order-item-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    NgIf,
    NgFor,
    NgStyle,
    NgClass,
    TranslateModule,
    CurrencyTranslatePipe,
    CurrencyShortNamePipe,
    OrderPreferenceComponent,
  ],
})
export class OrderItemDialogComponent implements OnInit {
  public order: OrderModel;

  public isMobile: boolean;

  public transitEvents = [];

  public aramexTrackingResults = [];

  public vhubsTrackingResults = [];

  public waybillTrackingDetail = [];

  public viewProducts = this.data.viewProducts;

  public currency: Currency;

  public isResizeImageEnabled = false;

  public orderPreference: MerchantOrderPreferencesModel;

  public shouldShowUpOrderPreference = false;

  constructor(
    private productService: ProductService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private shippingService: ShippingService,
    private clipboard: Clipboard,
    private toastr: ToastrService,
    private getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _getFeatureAttributeUsecase: GetFeatureAttributeUsecase,
    private _translateService: TranslateService,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.order = this.data.order;
    this.getProducts();
    this.currency = getCountryFromIsoCode3(this.order.country).currency;
    this.orderPreference = this.order?.merchantOrderPreferences;
    this.isOrderPreferenceEnabled();
  }

  isOrderPreferenceEnabled(): void {
    this.getFeatureFlagUseCase.execute(FEATURE_FLAGS.ORDER_PREFERENCE).subscribe((flag) => {
      this.shouldShowUpOrderPreference = flag;
    });
  }

  /* eslint-disable */
  mapNumbersToSuspendedReasons = (number: number) =>
    getSuspensionReasonByReasonNumberUseCase(number);

  mapNumbersToCustomerRejectedReasons = (number: number) =>
    getRejectionReasonByReasonNumberUseCase(number);

  /* eslint-enable */
  downloadAWB(): void {
    if (this.order.shippingInfo.company === 'bosta') {
      const formData = {
        packageId: this.order.shippingInfo.packageId,
      };
      this.shippingService.getAWBFromBosta(formData).subscribe({
        next: (res) => {
          this.saveByteArray('awb', this.base64ToArrayBuffer(res.data.data));
        },
      });
    } else if (this.order.shippingInfo.company === 'dreevo') {
      const link = document.createElement('a');
      link.href = this.order.shippingInfo.awb!;
      link.download = 'awb';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  base64ToArrayBuffer(base64: any): any {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  saveByteArray(reportName: any, byte: any): void {
    const blob = new Blob([byte], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const fileName = reportName;
    link.download = fileName;
    link.click();
  }

  _checkResizeImagesFeatureFlag(): void {
    this.getFeatureFlagUseCase.execute(FEATURE_FLAGS.RESIZE_IMAGES).subscribe({
      next: (flag) => {
        if (flag) {
          this._getFeatureAttributeUsecase
            .execute(FEATURE_FLAGS.ALLOWED_IDS_FOR_RESIZE_IMAGES)
            .subscribe((ids) => {
              ids = JSON.parse(ids) || [];
              this.isResizeImageEnabled = ids?.length === 0 || ids.includes(user.id);
            });
        }
      },
    });
  }

  getProducts(): void {
    this._checkResizeImagesFeatureFlag();

    const orderProducts = this.order.orderLines.map((line) => line.productId);

    this.productService.getProductsByProdIds(orderProducts).subscribe({
      next: (res: any) => {
        this.order.orderLines = this.order.orderLines.map((orderLine) => {
          if (this.isResizeImageEnabled) {
            orderLine.productPicture = getSizedImage(orderLine.productPicture!, ImageSize.small);
          }
          const product = res.data.find((p: any) => p.prodID === orderLine.productId);
          const productColorHex =
            product?.attributes &&
            product?.attributes.filter((attribute: any) => attribute.type === 'color')[0]?.value;
          return {
            ...orderLine,
            productName: orderLine.productName || product.productName,
            productPicture: orderLine.productPicture || product.productPicture,
            totalPrice: orderLine.totalPrice || product.productPrice,
            totalMerchantProfit: orderLine.totalMerchantProfit || product.productProfit,
            productColorHex,
            productColor: productColorHex && variants.getColorByHexCode(productColorHex).arabicName,
            productSize:
              product?.attributes &&
              product?.attributes.filter((attribute: any) => attribute.type === 'size')[0]?.value,
          };
        });
      },
    });
  }

  openChatModel(orderObj: any): void {
    /* eslint-disable */
    this._logMixpanelEventUseCase.execute({
      eventName: 'Open_order_message',
      payload: {
        'Order Id': orderObj.orderID,
        Status: orderObj.status,
        'Shipping Company':
          orderObj.shippingInfo && orderObj.shippingInfo.company
            ? orderObj.shippingInfo.company
            : 'No company',
      },
    });
    this.clipboard.copy(orderObj.orderID);
    /* eslint-enable */
    this.toastr.info(this._translateService.instant('ORDERS_PAGE.ORDER_ID_COPIED'));
    this.openIntegratedChat();
  }

  openIntegratedChat(): void {
    const script = this._renderer.createElement('script');
    script.type = `text/javascript`;
    script.text = `window.fcWidget.open()`;
    this._renderer.appendChild(this.document.body, script);
  }

  returnOrderStatus(recievedStatus: string): string {
    const statusObject = ORDER_STATUSES.ALL_STATUSES.find(
      (status) => status.statusInEnglish === recievedStatus,
    );
    return statusObject ? statusObject.statusTranslationKey : recievedStatus;
  }

  epochToJsDate(ts: string): string {
    const epochDate = ts.replace('/Date(', '').replace(')/', '');
    const date = new Date(parseInt(epochDate)); //eslint-disable-line
    return DateWrapperUtility.formatDateToUTCString(date, '');
  }
}
