<div mat-dialog-content>
  <h4 mat-dialog-title>
    {{ 'ORDERS_PAGE.ORDER_RETURN_DIALOG.RETURN_TITLE' | translate }}
  </h4>
  <form [formGroup]="refundOrderForm">
    <mat-form-field>
      <mat-label>{{
        'ORDERS_PAGE.ORDER_RETURN_DIALOG.PRODUCT_SELECTION_LABEL' | translate
      }}</mat-label>
      <mat-select formControlName="product" (selectionChange)="onProductChange($event)" required>
        <mat-option *ngFor="let item of products" [value]="item">
          <img loading="lazy" width="30px" height="30px" [src]="item.productPicture" />
          {{ item.productName }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="none">
      <label class="matLabel">{{
        'ORDERS_PAGE.ORDER_ACTIONS_DIALOGS.PRODUCT_QUANTITY_LABEL' | translate
      }}</label>
      <div class="inline-div">
        <label (click)="addQuantity()">+</label>
        <input type="number" formControlName="productQty" matInput class="qty-input" />
        <label (click)="removeQuantity()">-</label>
      </div>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{
        'ORDERS_PAGE.ORDER_RETURN_DIALOG.CHOOSE_RETURN_REASON' | translate
      }}</mat-label>
      <mat-select formControlName="issueReason" required>
        <mat-option [value]="1">{{
          'ORDERS_PAGE.ORDER_ACTIONS_DIALOGS.REASONS.PRODUCT_NOT_WORKING_REASON' | translate
        }}</mat-option>
        <mat-option [value]="2">{{
          'ORDERS_PAGE.ORDER_ACTIONS_DIALOGS.REASONS.WRONG_PRODUCT_REASON' | translate
        }}</mat-option>
        <mat-option [value]="3">{{
          'ORDERS_PAGE.ORDER_ACTIONS_DIALOGS.REASONS.USED_PRODUCT_REASON' | translate
        }}</mat-option>
        <mat-option [value]="4">{{
          'ORDERS_PAGE.ORDER_ACTIONS_DIALOGS.REASONS.PRODUCT_DEFECT_REASON' | translate
        }}</mat-option>
        <mat-option [value]="5">{{
          'ORDERS_PAGE.ORDER_ACTIONS_DIALOGS.REASONS.BATTERY_ISSUE_REASON' | translate
        }}</mat-option>
        <mat-option [value]="6">{{
          'ORDERS_PAGE.ORDER_ACTIONS_DIALOGS.REASONS.SHIPMENT_MISSING_REASON' | translate
        }}</mat-option>
        <mat-option [value]="7">{{
          'ORDERS_PAGE.ORDER_ACTIONS_DIALOGS.REASONS.NO_ISSUE_REASON' | translate
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="upload">
      <mat-label>
        {{ 'ORDERS_PAGE.ORDER_ACTIONS_DIALOGS.UPLOAD_VIDEO_NOTE' | translate }}
      </mat-label>
      <button (click)="OpenFile(true)" class="btn btn-upload">
        {{ 'ORDERS_PAGE.ORDER_ACTIONS_DIALOGS.UPLOAD_VIDEO_BTN_LABEL' | translate }}
      </button>
      <input
        type="file"
        maxSize="15360"
        style="display: none"
        accept="video/*"
        class="form-control-file"
        id="formControlVideo"
        (change)="onSendFile($event, true)"
      />
    </div>

    <div class="upload">
      <mat-label>
        {{ 'ORDERS_PAGE.ORDER_ACTIONS_DIALOGS.UPLOAD_PICTURE_NOTE' | translate }}
      </mat-label>
      <button (click)="OpenFile(false)" class="btn btn-upload">
        {{ 'ORDERS_PAGE.ORDER_ACTIONS_DIALOGS.UPLOAD_PICTURE_BTN_LABEL' | translate }}
      </button>
      <input
        type="file"
        maxSize="15360"
        style="display: none"
        accept="image/*"
        class="form-control-file"
        id="formControlImage"
        (change)="onSendFile($event, false)"
      />
    </div>
    <mat-form-field class="upload" appearance="none">
      <label class="matLabel">
        {{ 'ORDERS_PAGE.ORDER_RETURN_DIALOG.PHONE_NUMBER_INPUT_LABEL' | translate }}
      </label>
      <input formControlName="phoneNum" matInput />
    </mat-form-field>

    <mat-form-field appearance="none">
      <label class="matLabel">{{
        'ORDERS_PAGE.ORDER_ACTIONS_DIALOGS.EXPLAINATION_NOTES_LABEL' | translate
      }}</label>
      <textarea formControlName="notes" matInput></textarea>
    </mat-form-field>
  </form>
  <div mat-dialog-actions>
    <button mat-raised-button [disabled]="clicked" class="btn-submit" (click)="submit()">
      {{ 'ORDERS_PAGE.ORDER_ACTIONS_DIALOGS.SUBMIT_REQUEST_BTN_LABEL' | translate }}
    </button>
  </div>
</div>
